<script>
import { mapState } from "vuex";
const MiningGame = require("@/games/Mining/game");
// const ForagingGame = require("@/games/Foraging/game");
export default {
  components: {
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    skin: (state) => state.skin.data,
    hatData: (state) => state.apparel.data,
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    startTime: null,
    endTime: null,
    env: process.env.VUE_APP_ENV,
    noOfRounds: 3,
    containerId: "game-container",
    gameInstance: null,
    bossRound: -1, // make it random
    master: 0,
    music: 0,
    sfx: 0,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    friendApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    attemptApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    questions: [],
    avatarItem: {
      shadow: null,
      back: null,
      right: null,
      body: null,
      outline: null,
      cheek: null,
      mouth: null,
      eye: null,
      hair: null,
      left: null,
      hat: null,
      color: null,
    },
    friendName: null,
    friendAvatarItem: {
      shadow: null,
      back: null,
      right: null,
      body: null,
      outline: null,
      cheek: null,
      mouth: null,
      eye: null,
      hair: null,
      left: null,
      hat: null,
      color: null,
    },
    avatarData: null,
    avatarBack: null,
    avatarRight: null,
    avatarLeft: null,
    avatarOutline: null,
    avatarMouth: null,
    avatarBody: null,
    avatarShadow: null,
    avatarEye: null,
    avatarHair: null,
    avatarCheek: null,
    avatarColor: null,
    avatarHat: null,
    mouth: [],
    body: [],
    outline: [],
    shadow: [],
    eye: [],
    hair: [],
    cheek: [],
    hat: [],
    left: [],
    right: [],
    back: [],
  }),
  created() {
    if (this.$route.query.difficulty) {
      this.difficulty = this.$route.query.difficulty;
    } else {
      this.difficulty = 1;
    }
    this.api.url = this.$api.servers.question + "/questions";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.questions = resp;
      this.api.isLoading = false;

      if (this.auth.Classroom) {
        this.friendApi.params = {
          classroomId: this.auth.Classroom.id,
        };
        this.$api.fetch(this.friendApi);
      } else {
        this.startGame();
      }
    };

    this.friendApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/classmate";
    this.friendApi.callbackReset = () => {
      this.friendApi.isLoading = true;
      this.friendApi.isError = false;
      this.friendApi.error = null;
    };
    this.friendApi.callbackError = (e) => {
      this.friendApi.isLoading = false;
      this.friendApi.isError = true;
      this.friendApi.error = e;
    };
    this.friendApi.callbackSuccess = (resp) => {
      this.friendApi.isLoading = false;
      let randomFriend = Math.floor(Math.random() * resp.length);
      if (resp.length > 0 && resp[randomFriend].avatar) {
        this.friendName = resp[randomFriend].name;
        this.selectSkin(JSON.parse(resp[randomFriend].avatar), true);
      } else {
        this.startGame();
      }
    };

    this.attemptApi.url =
      this.$api.servers.log +
      "/api/v1/" +
      this.$_getLocale() +
      "/attempt/add";
    this.attemptApi.callbackReset = () => {
      this.attemptApi.isLoading = true;
      this.attemptApi.isError = false;
      this.attemptApi.error = null;
    };
    this.attemptApi.callbackError = (e) => {
      this.attemptApi.isLoading = false;
      this.attemptApi.isError = true;
      this.attemptApi.error = e;
    };
    this.attemptApi.callbackSuccess = () => {
      this.attemptApi.isLoading = false;
    };
  },
  mounted() {
      // this.attemptApi.params = this.event.submission; check thisxxx
      // this.$api.fetch(this.attemptApi);
      this.startTime = new Date();
      // this.bossRound = Math.floor(Math.random() * (this.noOfRounds - 2)) + 2;
      this.avatarData = JSON.parse(this.auth.Player.avatar);
      this.selectSkin(this.avatarData);
      this.api.params = {
        lang: this.$_getLocale(),
        count: this.noOfRounds,
        key: "s010_c010_q020",
        showOption: false,
      };
      this.$api.fetch(this.api);
  },
  beforeDestroy() {
    this.endTime = new Date(); // Record end time when element is about to be destroyed
    // const timeTakenInSeconds = this.endTime - this.startTime; // Calculate time taken in seconds
    // let event_data = this.$_.cloneDeep(this.event);
    // event_data.submission.time = timeTakenInSeconds;
    // this.$store.commit("updateEvent", event_data);

    let master = this.settings.audio.master;
    let music = this.settings.audio.music;
    this.$bgMusic.src = require("@/assets/sounds/music_bg_1.mp3");
    this.$bgMusic.volume = music * master;
    this.$bgMusic.play();
  },
  destroyed() {
    if (this.gameInstance) {
      this.gameInstance.destroy(true);
      this.gameInstance = null;
    }
  },
  methods: {
    startGame() {
      // if(codexxx){
        this.gameInstance = MiningGame.launch(this.containerId);
      // }else{
      //   this.gameInstance = ForagingGame.launch(this.containerId);
      // }
      this.gameInstance.scene.start("LoadingScene", {
        avatar: this.avatarItem,
        questions: this.questions,
        bossRound: this.bossRound,
        gemImage: "/assets/miningGame/gem.png",
        bossImage: "/assets/miningGame/gem.png",
        friend: {
          name: this.friendName,
          avatar: this.friendAvatarItem,
        },
        env: this.env,
        noOfRounds: this.noOfRounds,
        difficulty: this.difficulty,
      });
      this.$bgMusic.pause();
      this.$bgMusic.src = null;
    },
    selectSkin(avatarData, isFriend = false) {
      this.body = this.skin.body;
      this.outline = this.skin.outline;
      this.mouth = this.skin.mouth;
      this.shadow = this.skin.shadow;
      this.eye = this.skin.eye;
      this.hair = this.skin.hair;
      this.cheek = this.skin.cheek;
      var outline = avatarData.body;
      var mouth = avatarData.mouth;
      var hat = avatarData.hat;
      var left = avatarData.left;
      var right = avatarData.right;
      var back = avatarData.back;
      var avatarOutline = this.outline.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarOutline = JSON.parse(avatarOutline[0].images).F0.url;
      var avatarBody = this.body.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarBody = JSON.parse(avatarBody[0].images).F0.url;
      var avatarMouth = this.mouth.filter(function (entry) {
        return entry.character == mouth;
      });
      this.avatarMouth = JSON.parse(avatarMouth[0].images).F0.url;

      if (hat) {
        var avatarHat = this.hatData.filter(function (entry) {
          return entry.key == hat;
        });
        this.avatarHat = JSON.parse(avatarHat[0].images).apparel.url;
      }
      if (left) {
        var avatarLeft = this.hatData.filter(function (entry) {
          return entry.key == left;
        });
        this.avatarLeft = JSON.parse(avatarLeft[0].images).apparel.url;
      }
      if (right) {
        var avatarRight = this.hatData.filter(function (entry) {
          return entry.key == right;
        });
        this.avatarRight = JSON.parse(avatarRight[0].images).apparel.url;
      }
      if (back) {
        var avatarBack = this.hatData.filter(function (entry) {
          return entry.key == back;
        });
        this.avatarBack = JSON.parse(avatarBack[0].images).apparel.url;
      }

      this.avatarShadow = JSON.parse(this.shadow[0].images).F0.url;
      this.avatarEye = JSON.parse(this.eye[0].images).F0.url;
      this.avatarHair = JSON.parse(this.hair[0].images).F0.url;
      this.avatarCheek = JSON.parse(this.cheek[0].images).F0.url;
      this.avatarColor = avatarData.pColor;
      if (isFriend) {
        this.friendAvatarItem.back = this.avatarBack;
        this.friendAvatarItem.body = this.avatarBody;
        this.friendAvatarItem.eye = this.avatarEye;
        this.friendAvatarItem.hat = this.avatarHat;
        this.friendAvatarItem.left = this.avatarLeft;
        this.friendAvatarItem.mouth = this.avatarMouth;
        this.friendAvatarItem.right = this.avatarRight;
        this.friendAvatarItem.shadow = this.avatarShadow;
        this.friendAvatarItem.outline = this.avatarOutline;
        this.friendAvatarItem.cheek = this.avatarCheek;
        this.friendAvatarItem.hair = this.avatarHair;
        this.friendAvatarItem.color = this.avatarColor;
        this.startGame();
      } else {
        this.avatarItem.back = this.avatarBack;
        this.avatarItem.body = this.avatarBody;
        this.avatarItem.eye = this.avatarEye;
        this.avatarItem.hat = this.avatarHat;
        this.avatarItem.left = this.avatarLeft;
        this.avatarItem.mouth = this.avatarMouth;
        this.avatarItem.right = this.avatarRight;
        this.avatarItem.shadow = this.avatarShadow;
        this.avatarItem.outline = this.avatarOutline;
        this.avatarItem.cheek = this.avatarCheek;
        this.avatarItem.hair = this.avatarHair;
        this.avatarItem.color = this.avatarColor;
      }
    },
  },
};
</script>

<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="game-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <ApiErrorDialog v-if="friendApi.isError" :api="friendApi" />
          <!-- EOC -->
          <div
            :id="containerId"
            style="max-width: 480px; padding: 0; max-height: 100vh !important"
          />
          <div
            v-if="!gameInstance"
            style="position: absolute"
            class="d-flex align-center justify-center flex-column"
          >
            <v-progress-linear
              color="brown darken-1"
              indeterminate
              height="10"
              style="max-width: 200px"
              class="my-5"
            ></v-progress-linear>
            <span class="brown--text py-2">
              {{ $t("string.game_loading_msg") }}</span
            >

            <v-btn
              depressed
              color="brown lighten-3"
              class="brown--text text--darken-3"
              @click="
                () => {
                  $router.go($router.currentRoute);
                }
              "
            >
              ↻ {{ $t("action.retry") }}
            </v-btn>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>

<style>
.game-body {
  max-width: 480px;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>